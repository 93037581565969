/* global Swiper */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class PageVision extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new ArchiveSolutions');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.heroAnimation = this.DOM.view.querySelector('.Illu.is--solution #animContainer');
    this.DOM.systemeAnimation = this.DOM.view.querySelector('.Illu.is--systeme #animContainer');

    this.init();

  }

  init() {

    if (this.DOM.heroAnimation) this.initHeroVisual();
    if (this.DOM.systemeAnimation) this.initSystemeVisual();

  }

  initHeroVisual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.heroAnimation = lottie.loadAnimation({
      wrapper: this.DOM.heroAnimation,
      animType: 'svg',
      name: 'HeroAnimation',
      loop: true,
      path: `${window.themeUrl}/data/solutions.json`
    });

  }

  initSystemeVisual() {

    if (window.DEVMODE) console.log('Init initSystemeVisual');

    this.systemeAnimation = lottie.loadAnimation({
      wrapper: this.DOM.systemeAnimation,
      animType: 'svg',
      name: 'SystemeAnimation',
      loop: true,
      path: `${window.themeUrl}/data/systeme.json`
    });

  }

  onLeaveCompleted() {

    if (this.heroAnimation) lottie.destroy('HeroAnimation');
    if (this.systemeAnimation) lottie.destroy('SystemeAnimation');

  }
}
