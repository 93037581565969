/* global gsap */
import inView from 'in-view';
import { clamp, normalize } from '../utils.js';

export default class pagePrllx {

  constructor(container, pageScroll = null) {

    this.DOM = {
      elems: container.querySelectorAll('[data-prllxfrom]')
    };

    if (this.DOM.elems.length === 0) return;

    this.pageScroll = pageScroll;

    this.state = {
      scroll: {
        current: 0
      }
    };

    this.elems = null;
    this.onScroll = this.onScroll.bind(this);
    this.onRender = this.onRender.bind(this);

    this.init();
    this.addEvents();

  }

  init() {

    if (window.DEVMODE) console.log('Init prllx');

    this.elems = [];

    // Create PrllxElement
    this.DOM.elems.forEach((el) => {

      const tl = gsap.timeline({ paused: true, defaults: { duration: 1, ease: 'linear'}});
      const from = JSON.parse(el.dataset.prllxfrom);
      const to = JSON.parse(el.dataset.prllxto);

      tl.fromTo(el, from, to);

      this.elems.push({
        el: el,
        tl: tl,
        threshold: typeof el.dataset.prllxratio === 'undefined' ? 1 : el.dataset.prllxratio,
        progress: {
          current: 0
        }
      });

    });

  }

  onScroll() {
    this.state.scroll.current = window.pageYOffset || document.documentElement.scrollTop;
  }

  onRender() {

    let currentScroll;
    if (this.pageScroll) currentScroll = this.pageScroll.scroll.instance.delta.y;
    else currentScroll = this.state.scroll.current;

    this.elems.forEach(({el, tl, progress, threshold}) => {

      if (inView.is(el)) {

        const elTop = el.getBoundingClientRect().top;
        const elHeight = el.offsetHeight;

        const start = elTop + currentScroll - window.innerHeight <= 0 ? 0 : elTop + currentScroll - window.innerHeight;
        const end = elTop + currentScroll + elHeight;

        // Normalize
        let timelineProgress = gsap.utils.normalize(start, end, currentScroll);

        // Threshold
        if (threshold) {
          const remapProgress = gsap.utils.pipe(gsap.utils.mapRange(0, 1, 0, 1 / threshold));
          timelineProgress = remapProgress(timelineProgress);
        }

        // Play
        tl.progress(gsap.utils.clamp(0, 1, timelineProgress));
      }

    });

  }

  addEvents() {

    if (!this.pageScroll) window.addEventListener('scroll', this.onScroll, { passive: true });
    gsap.ticker.add(this.onRender);

  }

  destroy() {
    if (window.DEVMODE) console.log('Destroy prllx');
    window.removeEventListener('scroll', this.onScroll);
    gsap.ticker.remove(this.onRender);
  }

}
