/* global Swiper */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class PageHome extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new PageHome');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = document.querySelector('header.Header');
    this.DOM.home1Animation = this.DOM.view.querySelector('.Illu.is--home_1 #animContainer');
    this.DOM.home2Animation = this.DOM.view.querySelector('.Illu.is--home_2 #animContainer');
    this.DOM.home3Animation = this.DOM.view.querySelector('.Illu.is--home_3 #animContainer');

    this.init();

  }

  init() {

    if (this.DOM.home1Animation) this.initHome1Visual();
    if (this.DOM.home2Animation) this.initHome2Visual();
    if (this.DOM.home3Animation) this.initHome3Visual();
    this.DOM.header.classList.add('is--w');

  }

  initHome1Visual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.home1Animation = lottie.loadAnimation({
      wrapper: this.DOM.home1Animation,
      animType: 'svg',
      name: 'home1Animation',
      loop: true,
      path: `${window.themeUrl}/data/home_1.json`
    });

  }

  initHome2Visual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.home2Animation = lottie.loadAnimation({
      wrapper: this.DOM.home2Animation,
      animType: 'svg',
      name: 'home2Animation',
      loop: true,
      path: `${window.themeUrl}/data/home_2.json`
    });

  }

  initHome3Visual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.home2Animation = lottie.loadAnimation({
      wrapper: this.DOM.home3Animation,
      animType: 'svg',
      name: 'home3Animation',
      loop: true,
      path: `${window.themeUrl}/data/home_3.json`
    });

  }

  onLeaveCompleted() {

    if (this.CardsSwiper) this.CardsSwiper.destroy();
    this.DOM.header.classList.remove('is--w');
    if (this.home1Animation) lottie.destroy('home1Animation');
    if (this.home2Animation) lottie.destroy('home2Animation');
    if (this.home3Animation) lottie.destroy('home3Animation');

  }
}
