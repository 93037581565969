/* global gsap */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class ArchivePresse extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new ArchivePresse');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = document.querySelector('header.Header');
    this.DOM.newsContainer = this.DOM.view.querySelector('.Presse__grid');
    this.DOM.loadMore = this.DOM.view.querySelector('.Page__actions #load_more');
    this.DOM.heroAnimation = this.DOM.view.querySelector('.Illu.is--press #animContainer');

    this.fetchUrl = this.DOM.loadMore.dataset.fetch;
    this.currentPage = 1;

    this.init();

  }

  init() {

    this.DOM.header.classList.add('is--w');
    this.DOM.header.classList.add('is--l');
    this.addEvents();
    this.fetchNews();
    if (this.DOM.heroAnimation) this.initHeroVisual();

  }


  initHeroVisual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.heroAnimation = lottie.loadAnimation({
      wrapper: this.DOM.heroAnimation,
      animType: 'svg',
      name: 'HeroAnimation',
      loop: true,
      path: `${window.themeUrl}/data/press.json`
    });

  }

  addEvents() {

    console.log('plop');

    this.fetchNews = this.fetchNews.bind(this);
    this.DOM.loadMore.addEventListener('click', this.fetchNews);
  }

  onLeaveCompleted() {

    this.DOM.header.classList.remove('is--w');
    this.DOM.header.classList.remove('is--l');
    this.DOM.loadMore.removeEventListener('click', this.fetchNews);
    if (this.heroAnimation) lottie.destroy('HeroAnimation');

  }

  fetchNews(e) {

    const xhr = new XMLHttpRequest;

    fetch(`${this.fetchUrl}&page=${this.currentPage}`)
      .then(res => res.json())
      .then(res => {

        if (window.DEVMODE) console.log(res);
        this.DOM.newsContainer.innerHTML += res.html;

        // Animation
        gsap.fromTo(this.DOM.newsContainer.querySelectorAll('.Presse__preview'), {opacity: 0, y: 40}, {opacity: 1, y: 0, clearProps: 'all', duration: 1, ease: 'power3.out', stagger: 0.05});

        if (res.left > 0) {
          this.DOM.loadMore.style.display = 'inline-block';
          this.currentPage++;
        } else {
          this.DOM.loadMore.style.display = 'none';
        }
      });
  }

}
