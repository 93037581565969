/* global Swiper */
import Highway from '@dogstudio/highway';

export default class PageDetail extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new PageDetail');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = document.querySelector('header.Header');

    this.init();

  }

  init() {

    this.DOM.header.classList.add('is--w');

  }

  onLeaveCompleted() {

    this.DOM.header.classList.remove('is--w');

  }
}
