/* global gsap ScrollToPlugin */

export default class pageAnchors {

  constructor(container) {

    this.DOM = {
      container: container
    };

    this.DOM.AnchorsLinks = this.DOM.container.querySelectorAll('[data-anchor]');
    if (this.DOM.AnchorsLinks.length === 0) return;

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('Init Anchors');

    this.DOM.AnchorsLinks.forEach((item) => {

      item.addEventListener('click', (e) => {

        e.preventDefault();

        // anchors active
        if (this.DOM.container.querySelector('.is-active')) this.DOM.container.querySelector('.is-active').classList.remove('is-active');

        const targetAnchor = e.currentTarget.getAttribute('href');
        const targetOffset = e.currentTarget.dataset.offset;

        e.currentTarget.classList.add('is-active');
        gsap.to(window, {duration: 1.8, scrollTo: { y: targetAnchor, offsetY: targetOffset ? targetOffset : 0, autoKill: false }, ease: 'expo.inOut' });

      });
    });

  }
}
