/* global gsap */
import Highway from '@dogstudio/highway';

export default class VisionsTransition extends Highway.Transition {

  in({ from, to, done }) {

    // Remove Last Page
    if (window.DEVMODE) console.log('Leaving Page : ', from);

    // Timeline animation page enter
    const pageIn = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: 'power3.inOut'
      },
      onStart: () => {
        from.parentNode.removeChild(from);
        document.body.classList.remove('is--loading');
      },
      onComplete: () => {
        document.body.classList.remove('is--animating');
        done();
      }
    });

    pageIn.fromTo(to, { opacity: 0 }, { opacity: 1, clearProps: 'all' }, 0);
    pageIn.play();

  }

  out({ from, done }) {

    // Timeline animation page leave
    const pageOut = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: 'power3.inOut'
      },
      onStart: () => {
        document.body.classList.add('is--loading');
        document.body.classList.add('is--animating');
      },
      onComplete: () => {
        done();
      }
    });

    pageOut.to(from, { opacity: 0 }, 0);
    pageOut.play();

  }
}
