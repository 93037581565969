/* global Swiper */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class PageAbout extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new PageAbout');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.cardswiper = this.DOM.view.querySelector('.Cards__swiper');
    this.DOM.heroAnimation = this.DOM.view.querySelector('.Illu.is--about #animContainer');

    this.init();

  }

  init() {

    if (this.DOM.cardswiper) this.initCardsSwiper();
    if (this.DOM.heroAnimation) this.initHeroVisual();

  }

  initCardsSwiper() {

    if (window.DEVMODE) console.log('Init CardsSwiper');
    this.CardsSwiper = new Swiper(this.DOM.cardswiper, {
      spaceBetween: 10,
      slidesPerView: 1,
      breakpoints: {
        767: {
          slidesPerView: 2,
          spaceBetween: 16
        }
      }
    });

  }

  initHeroVisual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.heroAnimation = lottie.loadAnimation({
      wrapper: this.DOM.heroAnimation,
      animType: 'svg',
      name: 'HeroAnimation',
      loop: true,
      path: `${window.themeUrl}/data/about.json`
    });

  }

  onLeaveCompleted() {

    if (this.CardsSwiper) this.CardsSwiper.destroy();
    if (this.heroAnimation) lottie.destroy('HeroAnimation');

  }
}
