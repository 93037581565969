// Highway
import Highway from '@dogstudio/highway';
import PageHome from '../pages/page-home.js';
import PageAbout from '../pages/page-about.js';
import PageContact from '../pages/page-contact.js';
import PageVision from '../pages/page-vision.js';
import PageDemarrer from '../pages/page-demarrer.js';
import PageDetail from '../pages/page-detail.js';
import SingleLanding from '../pages/single-landing.js';
import ArchivePresse from '../pages/archive-presse.js';
import ArchiveSolutions from '../pages/archive-solutions.js';
import DefaultPageTransition from '../pages/page-transition.js';
import VisionsTransition from '../pages/page-vision-transition.js';
import lottie from 'lottie-web';

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';
import pageTabs from './tabs.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');
    this.navChildsLinks = this.header.DOM.el.querySelectorAll('.Nav__childs__link');

    this.createHighway();
    this.initView(document.body);

  }


  createHighway() {

    this.Highway = new Highway.Core({
      renderers: {
        pageHome: PageHome,
        pageAbout: PageAbout,
        pageContact: PageContact,
        pageVision: PageVision,
        pageDetail: PageDetail,
        pageDemarrer: PageDemarrer,
        singleLanding: SingleLanding,
        archivePresse: ArchivePresse,
        archiveSolutions: ArchiveSolutions
      },
      transitions: {
        default: DefaultPageTransition,
        contextual: {
          vision: VisionsTransition
        }
      }
    });

    this.Highway.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) console.log('NAVIGATE_OUT');

      // RemoveEffects
      if (this.view.anchors) this.view.anchors = null;
      if (this.view.inview) this.view.inview = null;
      if (this.demoAnimation) lottie.destroy('DemoAnimation');
      if (this.bookAnimation) lottie.destroy('BookAnimation');

      if (this.view.prllx) {
        this.view.prllx.destroy();
        this.view.prllx = null;
      }

    });

    this.Highway.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      if (window.DEVMODE) console.log('NAVIGATE_IN');

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is--active');
        if (link.href === location.href) {
          link.classList.add('is--active');
        }
      });

      this.navChildsLinks.forEach((link) => {
        link.classList.remove('is--active');
        if (link.href === location.href) {
          link.classList.add('is--active');
        }
      });

      this.initView(to.view);

    });

    this.Highway.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) console.log('NAVIGATE_END');


      // Analytics

      /* if (tarteaucitron.launch.gtag) {
        gtag('config', tarteaucitron.user.gtagUa, {
          'page_path': location.pathname,
          'page_title': to.page.title,
          'page_location': location.href
        });
      } */

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    if (container.querySelector('.Book')) {
      this.bookAnimation = lottie.loadAnimation({
        wrapper: container.querySelector('.Illu.is--book #animContainer'),
        animType: 'svg',
        name: 'BookAnimation',
        loop: false,
        path: `${window.themeUrl}/data/book.json`
      });
    }

    if (container.querySelector('.Demo')) {
      this.demoAnimation = lottie.loadAnimation({
        wrapper: container.querySelector('.Illu.is--demo #animContainer'),
        animType: 'svg',
        name: 'DemoAnimation',
        loop: false,
        path: `${window.themeUrl}/data/demo.json`
      });
    }

    if (container.querySelectorAll('[data-anchor]').length > 0) this.view.anchors = new pageAnchors(container);
    // if (container.querySelector('.Tabs__nav')) this.view.tabs = new pageTabs(container.querySelector('.Tabs__nav'));

    // Animations
    if (container.querySelectorAll('[data-prllxfrom]').length > 0) this.view.prllx = new pagePrllx(container, null);
    if (container.querySelectorAll('[data-inview]').length > 0) this.view.inview = new pageInView(container);

  }

}
