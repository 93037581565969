import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class PageDemarrer extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new PageDemarrer');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = document.querySelector('header.Header');
    this.DOM.heroAnimation = this.DOM.view.querySelector('.Illu.is--onboarding #animContainer');

    this.init();

  }

  init() {

    this.DOM.header.classList.add('is--w');
    this.DOM.header.classList.add('is--l');

    if (this.DOM.heroAnimation) this.initHeroVisual();

  }

  initHeroVisual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.heroAnimation = lottie.loadAnimation({
      wrapper: this.DOM.heroAnimation,
      animType: 'svg',
      name: 'HeroAnimation',
      loop: true,
      path: `${window.themeUrl}/data/demarrer.json`
    });

  }

  onLeaveCompleted() {

    this.DOM.header.classList.remove('is--w');
    this.DOM.header.classList.remove('is--l');
    if (this.heroAnimation) lottie.destroy('HeroAnimation');

  }
}
