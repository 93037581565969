/* global Swiper */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class PageContact extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new PageContact');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = document.querySelector('header.Header');
    this.DOM.heroAnimation = this.DOM.view.querySelector('.Illu.is--contact #animContainer');

    this.init();

  }

  init() {

    this.DOM.header.classList.add('is--w');
    this.DOM.header.classList.add('is--l');

    this.initForm();
    if (this.DOM.heroAnimation) this.initHeroVisual();

  }

  initHeroVisual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.heroAnimation = lottie.loadAnimation({
      wrapper: this.DOM.heroAnimation,
      animType: 'svg',
      name: 'HeroAnimation',
      loop: true,
      path: `${window.themeUrl}/data/contact.json`
    });

  }

  onLeaveCompleted() {

    this.DOM.header.classList.remove('is--w');
    this.DOM.header.classList.remove('is--l');
    if (this.heroAnimation) lottie.destroy('HeroAnimation');

  }

  initForm() {
    this.form = document.getElementById('ContactForm');
    const url = this.form.action;
    const form_output_error = this.form.querySelector('.form__output.is--error');
    const form_output_success = this.form.querySelector('.form__output.is--success');

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();

      let errors = this.form.querySelectorAll('.error');
      for (let err of errors) {
        err.parentNode.classList.remove('is--error');
        err.remove()
      }
      form_output_error.classList.add('hide')
      form_output_success.classList.add('hide')

      const xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);

      const formData = new FormData;

      const inputs = this.form.querySelectorAll('input, textarea');
      for (let input of inputs){
        if  (input.value){
          formData.append(input.name, input.value);
        }
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE){
          if (xhr.status === 200){

            form_output_success.classList.remove('hide')
            this.form.reset()

          } else if (xhr.status === 422){

            const json = JSON.parse(xhr.responseText);
            Object.keys(json).forEach((key) => {
              const input = this.form.querySelector('#'+key);

              const err = document.createElement('span');
              err.classList.add('error');
              err.innerHTML = json[key].join('<br/');
              input.parentNode.appendChild(err);
              input.parentNode.classList.add('is--error')
            })

          } else {

            form_output_error.classList.remove('hide')

          }
        }
      };
      xhr.send(formData)
    })
  }

}
