/* global Swiper */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class SingleLanding extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new SingleLanding');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.testimoniesswiper = this.DOM.view.querySelector('.Testimonies__swiper');
    this.DOM.heroAnimation = this.DOM.view.querySelector('.Illu.is--landing #animContainer');

    this.init();

  }

  init() {

    if (this.DOM.testimoniesswiper) this.initTestimoniesSwiper();
    if (this.DOM.heroAnimation) this.initHeroVisual();

  }

  initHeroVisual() {

    if (window.DEVMODE) console.log('Init initHeroVisual');

    this.heroAnimation = lottie.loadAnimation({
      wrapper: this.DOM.heroAnimation,
      animType: 'svg',
      name: 'HeroAnimation',
      loop: true,
      path: `${window.themeUrl}/data/landing.json`
    });

  }

  initTestimoniesSwiper() {

    if (window.DEVMODE) console.log('Init TestimoniesSwiper');
    this.TestimoniesSwiper = new Swiper(this.DOM.testimoniesswiper, {
      spaceBetween: 10,
      slidesPerView: 1,
      breakpoints: {
        767: {
          spaceBetween: 40
        }
      }
    });

  }

  onLeaveCompleted() {

    if (this.TestimoniesSwiper) this.TestimoniesSwiper.destroy();
    if (this.heroAnimation) lottie.destroy('HeroAnimation');

  }
}
