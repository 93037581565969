/* global Swiper */
import lottie from 'lottie-web';
import Highway from '@dogstudio/highway';

export default class PageVision extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new PageVision');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.pageAnimation = this.DOM.view.querySelector('.Illu.is--contraintes #animContainer');

    this.init();

  }

  init() {

    if (this.DOM.pageAnimation) this.initPageVisual();

  }

  initPageVisual() {

    if (window.DEVMODE) console.log('Init initPageVisual');

    this.pageAnimation = lottie.loadAnimation({
      wrapper: this.DOM.pageAnimation,
      animType: 'svg',
      name: 'PageAnimation',
      loop: true,
      path: `${window.themeUrl}/data/contraintes.json`
    });

  }

  onLeaveCompleted() {

    if (this.pageAnimation) lottie.destroy('PageAnimation');

  }
}
