/* global gsap */
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {

    const Loader = document.querySelector('.Loader');

    // Remove Last Page
    if (window.DEVMODE) console.log('Leaving Page : ', from);
    from.parentNode.removeChild(from);

    // Timeline animation page enter
    const pageIn = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: 'power3.inOut'
      },
      onStart: () => {
        document.body.classList.remove('is--loading');
      },
      onComplete: () => {
        document.body.classList.remove('is--animating');
        done();
      }
    });

    pageIn.fromTo(Loader.querySelector('.inner'), { y: 0 }, { y: '75%', ease: 'power3.inOut', clearProps: 'all' }, 0.8);
    pageIn.fromTo(Loader.querySelector('.brand svg'), {rotation: '0deg'}, {rotation: '-20deg', ease: 'power3.inOut'}, 0.8);
    pageIn.fromTo(Loader, { y: 0 }, { y: '-100%', ease: 'power3.inOut', clearProps: 'all' }, 0.8);
    pageIn.fromTo(to, { y: 100 }, { y: 0, clearProps: 'all' }, 0);
    pageIn.play();

  }

  out({ from, trigger, done }) {

    const Loader = document.querySelector('.Loader');

    // Timeline animation page leave
    const pageOut = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: 'power3.inOut'
      },
      onStart: () => {
        document.body.classList.add('is--loading');
        document.body.classList.add('is--animating');
      },
      onComplete: () => {
        done();
      }
    });

    pageOut.fromTo(Loader.querySelector('.brand'), { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: 'power3.out', clearProps: 'all' }, 0.4);
    pageOut.fromTo(Loader.querySelector('.brand svg'), {rotation: '25deg'}, {rotation: '0deg', ease: 'power3.out'}, 0.4);
    pageOut.fromTo(Loader.querySelector('#logo_shape'), {y: -10}, {y: 0, ease: 'power3.out'}, 0.4);
    pageOut.fromTo(Loader.querySelector('#logo_line'), {drawSVG: '0%'}, {drawSVG: '100%', ease: 'power3.out'}, 0.4);
    pageOut.fromTo(Loader.querySelector('#logo_shape_min'), {opacity: 0, y: 3, x: -3}, {opacity: 1, y: 0, x: 0, ease: 'power3.out'}, 0.7);
    pageOut.fromTo(Loader.querySelectorAll('#logo_petals > *'), {scale: 0, y: 3, x: -3, opacity: 0}, {scale: 1, y: 0, x: 0, opacity: 1, duration: 1, ease: 'power3.out', stagger: 0.03}, 0.7);
    pageOut.fromTo(Loader.querySelector('.inner'), { y: '-75%' }, { y: '0%' }, 0);
    pageOut.fromTo(Loader, { y: '100%' }, { y: '0%' }, 0);
    pageOut.to(from, { y: -20, clearProps: 'all' }, 0);
    pageOut.play();

  }
}
